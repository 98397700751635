// SearchBar.jsx
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import IconWrapper from "../../globals/IconWrapper";
import {
  showProfile,
  hideProfile,
  setUser,
} from "../../../store/selectedUserSlice";

function SearchBar({ className, handleSearchValue, searchValue }) {
  const [matchingUsers, setMatchingUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const searchBarRef = useRef(null);
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.selectedUser.user);
  const [loadingUserClick, setLoadingUserClick] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        dispatch(hideProfile());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarRef, dispatch]);

  const toggleList = () => {
    setIsOpen(!isOpen);
    dispatch(hideProfile());
  };

  const handleUserClick = useCallback(
    async (user) => {
      try {
        if (loadingUserClick) {
          console.log("User click request already in progress. Ignoring.");
          return;
        }

        setLoadingUserClick(true);
        console.log("Handling user click:", user);

        if (!user || !user.username) {
          console.error("Username is undefined or null");
          return;
        }

        // Create a new cancel token
        const source = axios.CancelToken.source();

        const response = await axios.get(
          `/api/search/users?username=${user.username}`,
          { cancelToken: source.token }
        );

        const userData = response.data.data[0];

        dispatch(showProfile());
        dispatch(setUser(userData));
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching user details:", error);
        }
      } finally {
        setLoadingUserClick(false);
      }
    },
    [dispatch, loadingUserClick]
  );

  const fetchMatchingUsers = async () => {
    try {
      if (searchValue.trim() !== "") {
        const response = await axios.get(
          `/api/search/users?username=${searchValue}`
        );
        setMatchingUsers(response.data.data);
        setIsOpen(true);
      } else {
        setMatchingUsers([]); // Clear the matching users when the search value is empty
        setIsOpen(false);
        dispatch(hideProfile());
      }
    } catch (error) {
      console.error("Error fetching matching users:", error);
    }
  };

  useEffect(() => {
    fetchMatchingUsers();
  }, [searchValue, dispatch]);

  return (
    <div
      className={`flex items-center gap-[.5rem] group border bg-search border-search-border rounded-full pl-[1rem] focus-within:border-cta-icon focus-within:border-2 ${className}`}
    >
      {/* Search Icon */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 32 32"
        className="w-[2rem] h-[2rem]"
      >
        <path
          fill="currentColor"
          d="m29 27.586l-7.552-7.552a11.018 11.018 0 1 0-1.414 1.414L27.586 29ZM4 13a9 9 0 1 1 9 9a9.01 9.01 0 0 1-9-9Z"
          strokeWidth={1}
          className="group-focus-within:fill-cta-icon group-focus-within:stroke-cta-icon stroke-transparent"
        />
      </svg>
      {/* Input box */}
      <input
        type="text"
        name="search"
        className="flex-grow focus-within:outline-none bg-transparent pl-8"
        placeholder="Search"
        value={searchValue}
        onChange={handleSearchValue}
        onClick={toggleList}
      />
      {/* Matching Users List */}
      {isOpen && matchingUsers.length > 0 && (
        <div
          className="absolute z-10 bg-white bg-opacity-0 border-none rounded mt-2 overflow-hidden w-full"
          style={{
            top: "4rem",
            left: "12rem",
            width: "200px",
            height: "100px",
          }}
        >
          <ul>
            {matchingUsers.map((user, index) => (
              <li
                key={user._id || index}
                className="cursor-pointer hover:bg-gray-100 bg-opacity-50 p-2"
                onClick={() => handleUserClick(user)}
              >
                {user.username}
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* Clear Icon */}
      <IconWrapper
        className={`${!searchValue && "opacity-0"}`}
        onClick={() => handleSearchValue({ target: { value: "" } })}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 32 32"
        >
          <path
            fill="currentColor"
            d="M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4l6.6 6.6L8 22.6L9.4 24l6.6-6.6l6.6 6.6l1.4-1.4l-6.6-6.6L24 9.4z"
            strokeWidth={1}
            className="group-focus-within:fill-cta-icon group-focus-within:stroke-cta-icon stroke-transparent"
          />
        </svg>
      </IconWrapper>
    </div>
  );
}

export default SearchBar;
