// SelectedUserProfile.jsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import useTime from "../hooks/useTime";
import Image from "../components/globals/Image";
import IconWrapper from "../components/globals/IconWrapper";
import Header from "../components/globals/Header";

const SelectedUserProfile = ({ selectedUser, onDeselect }) => {
  const [userData, setUserData] = useState(null);
  const lastSeenTime = useTime(userData?.status?.lastSeen);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!selectedUser || !selectedUser.username) {
          console.error("Username is undefined or null");
          setUserData(null);
          return;
        }

        const response = await axios.get(
          `/api/search/users?username=${selectedUser.username}`
        );

        const fetchedUserData = response.data.data[0];
        setUserData(fetchedUserData);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUserData(null);
      }
    };

    if (selectedUser) {
      fetchData();
    } else {
      setUserData(null);
    }
  }, [selectedUser]);

  const handleDeselect = () => {
    setUserData(null);
    if (onDeselect) {
      onDeselect();
    }
  };

  return (
    <div
      className={`bg-primary duration-200 ease-in-out ${
        userData
          ? "w-[40rem] sm:w-full xl:translate-x-0"
          : "w-0 xl:w-[40rem] xl:translate-x-[50rem]"
      }  h-full shrink-0 xl:absolute xl:top-0 xl:right-0 xl:z-20 xl:shadow-lg xl:shadow-box-shadow`}
    >
      {userData && (
        <>
          <Header className="flex items-center px-[1rem]">
            <IconWrapper
              onClick={handleDeselect}
              className="text-secondary-text"
            >
              {/* Close icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 32 32"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <path d="M2 30 L30 2 M30 30 L2 2"></path>
              </svg>
            </IconWrapper>
          </Header>

          <div className="h-[40rem] relative">
            <Image
              src={userData.avatar}
              alt={userData.name || userData.username}
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-[2rem] left-[2rem]">
              <p className="text-[2rem] font-semibold text-white">
                {userData.name || userData.username}
              </p>
              <p className="text-secondary-text">
                {userData.status?.online
                  ? "Online"
                  : `last seen at ${lastSeenTime}`}
              </p>
            </div>
          </div>

          <div className="p-[1rem] overflow-y-scroll custom-scrollbar">
            <div className="flex items-center gap-[3rem] hover hover:bg-secondary-light-text p-[.5rem] rounded-md ">
              {/* At icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 16 16"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  className="stroke-secondary-text"
                >
                  <path d="M10.25 8c0 3.25 4 3.25 4 0A6.25 6.25 0 1 0 8 14.25c2.25 0 3.25-1 3.25-1" />
                  <circle cx="8" cy="8" r="2.25" />
                </g>
              </svg>
              <div className="flex flex-col">
                <span className="font-semibold">{userData.username}</span>
                <span className="text-secondary-text">Username</span>
              </div>
            </div>

            {userData.bio && (
              <div className="flex items-center gap-[3rem] hover hover:bg-secondary-light-text p-[.5rem] rounded-md ">
                {/* Info icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 32 32"
                  className="shrink-0"
                >
                  <g
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="stroke-secondary-text"
                  >
                    <path d="M16 14v9m0-15v2" />
                    <circle cx="16" cy="16" r="14" />
                  </g>
                </svg>
                <div className="flex flex-col flex-grow">
                  <p className="font-semibold break-words pr-[2rem]">
                    {userData.bio}
                  </p>
                  <span className="text-secondary-text">Bio</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectedUserProfile;
