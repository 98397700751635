// selectedUserSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
  user: {},
};

const selectedUserSlice = createSlice({
  name: "selectedUser",
  initialState,
  reducers: {
    showProfile: (state) => {
      state.visible = true;
    },
    hideProfile: (state) => {
      state.visible = false;
      state.user = {}; // Reset the user when hiding the profile
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export const { showProfile, hideProfile, setUser, setSelectedUser } =
  selectedUserSlice.actions;

export default selectedUserSlice.reducer;
